import styled from 'styled-components';

const FooterContainer = styled.div`
    bottom: 0;
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
`;

const Footer = () => {
    return (
        <>
            <FooterContainer>
                <br />
                <p>©2023 by Dikaios Studios</p>
            </FooterContainer>
        </>
    );
};

export default Footer;