import PageHeader from '../components/PageHeader';
import PhotoJoseph from '../assets/photos/photo-joseph.jpeg';
import PhotoStan from '../assets/photos/photo-stan.jpg';

import styled from 'styled-components';

const Photo = styled.img`
  max-width: 100%;
  height: auto; 
`;

const ParentContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const ProfileContainer = styled.div`
  padding: 10px 0 10px min(50px, 10%);  
  width: 400px;
  font-size: 17px;
  @media screen and (max-width: 600px) {
    width: 300px;
  }
`;

const SubContainer = styled.div`

`;

const About = () => {
  return (
    <>
      <PageHeader pageName="About Us"/>
        <ParentContainer>
        <ProfileContainer>
            <Photo src={PhotoJoseph} />

          <h2>Joseph C. Ong</h2>
            <h3>Founder & Chief Executive Officer (CEO)</h3>
            <p>
Joseph has been a Project Controls professional since 2008 and a certified Project Management Professional (PMP). He has been working in engineering and construction projects in various sectors, specialising in scheduling and cost controlling.
</p>
<p>Since his teenage years until today, he has been playing a lot of Role Playing Games (RPG), thus making him into a fanatic RPG gamer. Growing up playing RPGs has eventually shaped him now to become someone who has a vision in developing his own games. With the support from his team, Joseph is confident that Dikaios Studios is capable of making great games in future.
            </p>
        </ProfileContainer>
        <ProfileContainer>
            <Photo src={PhotoStan} />
            <h2>Stanley Darmawan</h2>
              <h3>Co-Founder & Chief Information Officer (CIO)</h3>
              <p>
              Stanley started his career as an Animator, back in 2006. He had worked in gaming, filming and advertisement industries. Then in 2016 he decided to change career to become a software engineer, up until now. His expertise includes blockchain, mobile app and website developments.
              </p>
              <p>
              He always loves playing Role Playing Games (RPG), both 2D and 3D styles. With his background in animation and software engineering, he’s very motivated in developing his own game.
              </p>
        </ProfileContainer>
        </ParentContainer>
    </>
  )
}

export default About