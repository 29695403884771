import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Heading = styled.h1`
    text-align: left;
    padding: 10px 0 10px 50px;  
`;

export default class PageHeader extends Component {
    render() {
        return (
            <Heading>
                {this.props.pageName}
            </Heading>
        );
    }
}

PageHeader.propTypes = {
    pageTitle: PropTypes.string
}
