import './App.css'; 
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from './pages/Home';
import About from './pages/About';
import Product from './pages/Product';
import Contact from './pages/Contact';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  return (
      <div>
          <Header />
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/about' element={ <About />} />
              <Route path='/product' element={ <Product />} />
              <Route path='/contact' element={ <Contact />} />
            </Routes>
          </BrowserRouter>
          <Footer />
      </div>

  );
}

export default App;
