import { Link } from "react-router-dom";
import DrawerMenu from './DrawerMenu';
import styled from 'styled-components';

const Logo = styled.h1`
    font-size: 60px;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Push = styled.div`
    margin-left: auto;
`;

const Header = () => {
    return (
        <>
        <section>
            <HeaderContainer>
                <div />
                <Logo>Dikaios Studios</Logo>
                <div><DrawerMenu/></div>
            </HeaderContainer>
            </section>
        </>
    );
};

export default Header;