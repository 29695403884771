import PageHeader from '../components/PageHeader';
import ComingSoon from '../components/ComingSoon';

const Contact = () => {
    return (
      <>
      <PageHeader pageName="Contact Us"/>
      <ComingSoon />
      </>
    )
  }
  
  export default Contact