import PageHeader from '../components/PageHeader';
import ComingSoon from '../components/ComingSoon';

const Home = () => {
  return (
    <>
    <PageHeader pageName="Home"/>
    <ComingSoon />
    </>
  );
};

export default Home;