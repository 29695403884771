import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    text-align: center;
    padding-top: 100px;
    height: 200px;
    font-size: 20px;
`;

const ComingSoon = () => {
    return (
        <>
            <Container>
                    <p>
                        Coming soon
                    </p>
            </Container>
        </>
    );
};

export default ComingSoon;