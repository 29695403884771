import PageHeader from '../components/PageHeader';
import ComingSoon from '../components/ComingSoon';

const Product = () => {
  return (
    <>
    <PageHeader pageName="Product"/>
    <ComingSoon />
    </>
  );
}

export default Product