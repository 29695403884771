import React from 'react';
import styled from 'styled-components';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'

const CloseButton = styled.div`
    height: 100px;
    padding-top: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
    color: white;
    font-size: 30px;
`;

const NavigationLink = styled.a`
    color: white;
    font-size: 30px;
`;

const Panel = styled.div`
    height: 100%;
    background-color: #315b93;
`;

const DrawerMenu = () => {
    const [isOpen, setIsOpen] = React.useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    return (
        <>
            <i class="fa fa-bars" onClick={toggleDrawer}></i>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='right'
                enableOverlay={false}
                size={350}
                className='drawer'
            >
                <Panel>
                    <CloseButton onClick={toggleDrawer}>
                        X
                    </CloseButton>
                    <div>
                        <ul>
                            <li>
                                <NavigationLink href='/' onClick={toggleDrawer}>Home</NavigationLink>
                            </li>
                            <li>
                                <NavigationLink href='/about' onClick={toggleDrawer}>About us</NavigationLink>
                            </li>
                            <li>
                                <NavigationLink href='/product' onClick={toggleDrawer}>Product</NavigationLink>
                            </li>
                            <li>
                                <NavigationLink href='/contact' onClick={toggleDrawer}>Contact us</NavigationLink>
                            </li>
                        </ul>
                    </div>
                </Panel>
            </Drawer>
        </>
    )
}

export default DrawerMenu